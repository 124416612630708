import React from 'react'
import { Link } from 'gatsby'

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  let navigation = [
    {
      name: 'home',
      title: 'Home',
      url: '/',
    },
    {
      name: 'about',
      title: 'About',
      url: '/about/',
    },
  ]

  if (process.env.NODE_ENV !== 'production') {
    navigation = [
      ...navigation,
      {
        name: 'elements',
        title: 'Elements',
        url: '/elements/',
      },
    ]
  }

  const socials = [
    {
      title: 'Dribbble',
      url: 'https://dribbble.com/dajana_h',
    },
    {
      title: 'Bēhance',
      url: 'https://www.behance.net/dajanalugoa87a',
    },
  ]

  return (
    <div className={`site-wrapper${toggleNav ? ` site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`/#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              {navigation.map(item => (
                <li
                  key={item.name}
                  className={`nav-${item.name}`}
                  role="menuitem"
                >
                  <Link to={item.url} activeClassName={`active-class`}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              {socials.map(item => (
                <a
                  href={item.url}
                  title={item.title}
                  target={`_blank`}
                  rel={`noopener noreferrer`}
                  key={item.url}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> &mdash;
        Built by{' '}
        <a
          href="https://www.radovanhajdukovic.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width="15" src="https://www.radovanhajdukovic.com/logo.svg" />
        </a>
      </footer>
    </div>
  )
}

export default Layout
